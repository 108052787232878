<template>
    <!-- Card -->
    <div :class="{ 'col-lg-9' : props.col===true}">
        <div class="card active">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
                <div class="flex-grow-1">
                <span class="d-lg-none">{{props.step}}</span>
                <h3 class="card-header-title">Profile</h3>
                </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
                <!-- Form Group -->
                <div class="form-group">
                <label for="firstNameLabel" class="input-label">First name</label>
                <input type="text" v-model="userProfile.firstName" class="form-control" id="firstNameLabel" placeholder="Maria">
                </div>
                <!-- End Form Group -->
                <!-- Form Group -->
                <div class="form-group">
                <label for="lastNameLabel" class="input-label">Last name</label>
                <input type="text" v-model="userProfile.lastName" class="form-control" id="lastNameLabel" placeholder="Maria">
                </div>
                <!-- End Form Group -->
                <!-- Form Group -->
                <div class="form-group">
                <label for="addressLabel" class="input-label">Address</label>
                <p class="small mt-n2">Please provide your billing address</p>
                <input type="text" v-model="userProfile.address" class="form-control" id="addressLabel" placeholder="Maria">
                </div>
                <!-- End Form Group -->
                <!-- Form Group -->
                <div class="form-group">
                <label for="countryLabel" class="input-label">Country</label>
                    <p class="small mt-n2">Please provide your billing country</p>
                <input type="text" v-model="userProfile.country" class="form-control" id="countryLabel" placeholder="Maria">
                </div>
                <!-- End Form Group -->
                <!-- Form Group -->
                <div class="form-group">
                <label for="phoneLabel" class="input-label">Phone number</label>
                <p class="small mt-n2">Optional</p>
                <input type="text" v-model="userProfile.phone" class="form-control" id="phoneLabel" placeholder="Maria">
                </div>
                <!-- End Form Group -->
            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
                <div class="d-flex justify-content-end align-items-center">
                <button @click="save" type="button" class="btn btn-dark">
                    {{props.next}} <i class="fas fa-angle-right ml-1"></i>
                </button>
                </div>
            </div>
            <!-- End Footer -->
        </div>
        <!-- End Card -->
    </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const userProfile = computed(() => store.state.userProfile)

const emit = defineEmits(['saved'])
const props = defineProps({
    step: String,
    next: String,
    col: Boolean
  })

const save = () => {
  store.dispatch('updateUserProfile', userProfile.value)
  .then(() => { 
      emit('saved')
  })
  .catch(error => {
    console.log(error)
  })
}
</script>