<template>
  <main id="content" role="main" class="">

    <!-- Hero Section -->
    <div class="position-relative border-bottom">

      <div class="gradient-y-overlay-sm-gray-900 bg-img-hero" style="background-image: url(./assets/img/cover-surf.jpg);">
        <!-- Slide #1 -->
        <div class="container d-md-flex align-items-md-center vh-md-70 space-top-4 space-bottom-3 space-md-0">
          <div class="w-80 w-lg-50">
            <span class="d-block h3 text-white mb-2">
              Welcome to Savelocal
            </span>
            <h1 class="text-white display-4 mb-0">
              First complete<br>your registration
            </h1>
          </div>
        </div>
        <!-- End Slide #1 -->
        <div class="postion-absolute bottom-0 right-0 left-0 pb-3 text-center">
            <div class="container-fluid" style="color:#E5E5E5; opacity:0.3">
                <i class="fa fa-map-marker-alt"></i>
                <span> Bali, Indonesia - Photo by <a href="https://unsplash.com" target="_blank" style="color:inherit;text-decoration:underline">Jeremy Bishop</a></span>
            </div>
        </div>
      </div>

    </div>
    <!-- End Hero Section -->

    <!-- Step Section -->
    <div class="container space-2 space-top-md-2 space-top-lg-2">
      <!-- Step Form -->
      <div class="row">
        <div  class="col-lg-4">
          <!-- Step -->
          <ul class="step step-icon-xs step-border-last-0 mt-5">
            <li class="step-item" :class="wizard===1?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>1?'step-icon-dark':'step-icon-soft-dark'">1</span>
                <div class="step-content">
                  <span class="step-title">Contact information</span>
                  <span class="step-title-description step-text font-size-1">General info about you</span>
                </div>
              </div>
            </li>

            <li class="step-item" :class="wizard===2?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>2?'step-icon-dark':'step-icon-soft-dark'">2</span>
                <div class="step-content">
                  <span class="step-title">Donation</span>
                  <span class="step-title-description step-text font-size-1">Set your monthly spending</span>
                </div>
              </div>
            </li>

            <li class="step-item" :class="wizard===3?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>3?'step-icon-dark':'step-icon-soft-dark'">3</span>
                <div class="step-content">
                  <span class="step-title">Payment</span>
                  <span class="step-title-description step-text font-size-1">Specify your payment method</span>
                </div>
              </div>
            </li>
          </ul>

          <ul class="step step-icon-xs step-border-last-0">

            <li class="step-item">
              <div class="step-content-wrapper">
                <span class="step-title"></span>
                <div class="step-content">
                  <span class="badge badge-soft-dark text-dark text-uppercase ml-6">optional</span>
                </div>
              </div>
            </li>

            <li class="step-item" :class="wizard===4?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>4?'step-icon-dark':'step-icon-soft-dark'">4</span>
                <div class="step-content">
                  <span class="step-title">Social media</span>
                  <span class="step-title-description step-text font-size-1">Link your social accounts</span>
                </div>
              </div>
            </li>

            <li class="step-item" :class="wizard===5?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>5?'step-icon-dark':'step-icon-soft-dark'">5</span>
                <div class="step-content">
                  <span class="step-title">Default charities</span>
                  <span class="step-title-description step-text font-size-1">Change your default charities</span>
                </div>
              </div>
            </li>

            <li class="step-item" :class="wizard===6?'active focus':''">
              <div class="step-content-wrapper">
                <span class="step-icon" :class="wizard>6?'step-icon-dark':'step-icon-soft-dark'">6</span>
                <div class="step-content">
                  <span class="step-title">Contribute with skills</span>
                  <span class="step-title-description step-text font-size-1">Link your social accounts</span>
                </div>
              </div>
            </li>
          </ul>
          <!-- End Step -->
        </div>
      
        <div class="col-lg-8">
          
          <Profile v-if="wizard==1" @saved="wizard++" step="Step 1 of 6" next="Save and continue"></Profile>

          <!-- Card -->
          <div class="card active" v-if="wizard==2">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
              <div class="flex-grow-1">
                <span class="d-lg-none">Step 2 of 6</span>
                <h3 class="card-header-title">Donation</h3>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <!-- Form Group -->
              <div class="form-group">
                <label for="addressLabel" class="input-label">Donation amount</label>
                <p class="small mt-n2">You can update the amount at any time. The minimum amount is €5, the maximum €10.000.</p>
                <input type="text" v-model="userProfile.amount" class="form-control" id="addressLabel" placeholder="Monthly amount in €">
              </div>
              <!-- End Form Group -->
              <!-- Form Group -->
              <div class="form-group">
                <label for="CVPrivacySwitch" class="input-label">End date</label>

                <div class="row">
                  <div class="col col-sm-9">
                    <label for="CVPrivacySwitch" class="input-label text-body">Optionally you can set an end date, after the specified amount of months your subscription automatically stops.</label>
                  </div>

                  <div class="col-auto col-sm-3">
                    <!-- Checkbox Switch -->
                    <label class="toggle-switch d-flex justify-content-end" for="CVPrivacySwitch">
                      <input v-model="userProfile.endDate" type="checkbox" class="toggle-switch-input" id="CVPrivacySwitch">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                    </label>
                    <!-- End Checkbox Switch -->
                  </div>
                </div>
  
                <input v-if="userProfile.endDate" v-model="userProfile.monthsRemaining" type="text" class="form-control mt-2" placeholder="Amount of months you want to donate">

                <!-- End Row -->
              </div>
              <!-- End Form Group -->
            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <button type="button" class="btn btn-ghost-secondary" @click="wizard-=1">
                  <i class="fas fa-angle-left mr-1"></i> Previous step
                </button>

                <div class="ml-auto">
                  <button type="button" class="btn btn-dark" @click="save">
                    Save and continue <i class="fas fa-angle-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="card active" v-if="wizard==3">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
              <div class="flex-grow-1">
                <span class="d-lg-none">Step 3 of 6</span>
                <h3 class="card-header-title">Payment</h3>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              Not implemented yet              
            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <button type="button" class="btn btn-ghost-secondary" @click="wizard-=1">
                  <i class="fas fa-angle-left mr-1"></i> Previous step
                </button>

                <div class="ml-auto">
                  <button type="button" class="btn btn-dark" @click="save">
                    Save and continue <i class="fas fa-angle-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="card active" v-if="wizard==4">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
              <div class="flex-grow-1">
                <span class="d-lg-none">Step 4 of 6</span>
                <h3 class="card-header-title">Social media</h3>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">

              <div class="row mx-n2" v-if="!explainWoo">
                <div class="col-sm-6 col-md-4 px-2 mb-3 mb-md-0">
                  <!-- Card -->
                  <a class="card card-frame h-100" href="#">
                    <div class="card-header bg-light text-center rounded-lg-top py-4">
                      <div class="avatar d-block bg-white rounded mx-auto">
                        <img class="avatar-img" src="../../public/assets/img/instagram-icon.png" alt="Image Description">
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <span class="d-block text-dark font-weight-bold">Instagram</span>
                      </div>
                      <span class="d-block text-body font-size-1">Save your stories</span>
                    </div>
                  </a>
                  <!-- End Card -->
                </div>

                <div class="col-sm-6 col-md-4 px-2 mb-3 mb-md-0">
                  <!-- Card -->
                  <a class="card card-frame h-100" href="#">
                    <div class="card-header bg-light text-center rounded-lg-top py-4">
                      <div class="avatar d-block bg-white rounded mx-auto">
                        <img class="avatar-img" src="../../public/assets/img/strava-icon.png" alt="Image Description">
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <span class="d-block text-dark font-weight-bold">Strava</span>
                      </div>
                      <span class="d-block text-body font-size-1">Save your workout spots</span>
                    </div>
                  </a>
                  <!-- End Card -->
                </div>

                <div class="col-sm-6 col-md-4 px-2">
                  <!-- Card -->
                  <a @click="explainWoo=true" class="card card-frame h-100">
                    <div class="card-header bg-light text-center rounded-lg-top py-4">
                      <div class="avatar d-block bg-white rounded mx-auto">
                        <img class="avatar-img" src="../../public/assets/img/woo-icon.png" alt="Image Description">
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="d-flex align-items-center mb-1">
                        <span class="d-block text-dark font-weight-bold">Woosports</span>
                      </div>
                      <span class="d-block text-body font-size-1">Save the waves you kite</span>
                    </div>
                  </a>
                  <!-- End Card -->
                </div>
              </div>
  
              <div v-if="explainWoo">
                To link your Woosports account:<br><br>
                <ol>
                  <li>Open the Woo app on your phone</li>
                  <li>Go to Settings</li>
                  <li>Go to Savelocal (you need at least version X of the app)</li>
                  <li>Enter the email you use for Savelocal: {{userProfile.email}}</li>
                </ol>
                <br>
                <button type="button" class="btn btn-soft-info" @click="explainWoo=false">
                  <i class="fas fa-angle-left mr-1"></i> Back to overview
                </button>
              </div>

            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <button type="button" class="btn btn-ghost-secondary" @click="wizard-=1">
                  <i class="fas fa-angle-left mr-1"></i> Previous step
                </button>

                <div class="ml-auto">
                  <button type="button" class="btn btn-dark" @click="save">
                    Continue <i class="fas fa-angle-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="card active" v-if="wizard==5">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
              <div class="flex-grow-1">
                <span class="d-lg-none">Step 5 of 6</span>
                <h3 class="card-header-title">Default charities</h3>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">

              <!-- Form Group -->
              <div class="form-group">

                <label for="CVPrivacySwitch" class="input-label">Manual selection</label>

                <div class="row">
                  <div class="col col-sm-9">
                    <label for="CVPrivacySwitch" class="input-label text-body">
                      If you don't tag projects in a given month your donation is split between all charities on our platform.
                      Alternatively you can manually select them.
                    </label>
                  </div>

                  <div class="col-auto col-sm-3">
                    <!-- Checkbox Switch -->
                    <label class="toggle-switch d-flex justify-content-end" for="CVPrivacySwitch">
                      <input v-model="userProfile.charitiesManual" type="checkbox" class="toggle-switch-input" id="CVPrivacySwitch">
                      <span class="toggle-switch-label">
                        <span class="toggle-switch-indicator"></span>
                      </span>
                    </label>
                    <!-- End Checkbox Switch -->
                  </div>
                </div>
              </div> 

              <!-- Card -->
              <div v-if="userProfile.charitiesManual" >
                <label class="input-label">Click on a charity to (de)select</label>
                <div v-for="(org, index) in store.state.organizations" :key="index"
                  @click="userProfile.charitiesDefault[index]=!userProfile.charitiesDefault[index]"
                  class="card py-3 px-4 mb-2" :class="userProfile.charitiesDefault[index]?'bg-primary':'bg-danger'">
                  <div class="row align-items-sm-center">
                    <span class="col-sm-6 text-dark">
                      {{org.name}}
                    </span>
                    <span class="col-6 col-sm-3 text-white">
                      {{org.scope}}
                    </span>
                    <span class="col-6 col-sm-3 text-right">
                      
                      <a class="badge badge-warning badge-pill p-2" :href="org.website" target="_blank">
                        Visit &nbsp;&nbsp;<i class="fa fa-external-link-alt" style="opacity:0.7"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <!-- End Card -->

            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <button type="button" class="btn btn-ghost-secondary" @click="wizard-=1">
                  <i class="fas fa-angle-left mr-1"></i> Previous step
                </button>

                <div class="ml-auto">
                  <button type="button" class="btn btn-dark" @click="save">
                    Save and continue <i class="fas fa-angle-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->

          <!-- Card -->
          <div class="card active" v-if="wizard==6">
            <!-- Header -->
            <div class="card-header bg-light py-4" >
              <div class="flex-grow-1">
                <span class="d-lg-none">Step 6 of 6</span>
                <h3 class="card-header-title">Contribute with skills</h3>
              </div>
            </div>
            <!-- End Header -->

            <!-- Body -->
            <div class="card-body">
              <!-- Form Group -->
              <div class="form-group">
                <label class="input-label">Do you want to help out savelocal? </label>

                <div class="row mx-n2">
                  <div class="col-sm-6 px-2">
                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox1">
                        <label class="custom-control-label" for="skillsCheckbox1">Front-End Developer</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox2">
                        <label class="custom-control-label" for="skillsCheckbox2">Back-End Developer</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox3">
                        <label class="custom-control-label" for="skillsCheckbox3">Mobile Developer</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox4">
                        <label class="custom-control-label" for="skillsCheckbox4">Graphic/UI/UX Designer</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->
                  </div>

                  <div class="col-sm-6 px-2">
                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox5">
                        <label class="custom-control-label" for="skillsCheckbox5">Ecologist / Conservationist</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox6">
                        <label class="custom-control-label" for="skillsCheckbox6">Growth hacker</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox7">
                        <label class="custom-control-label" for="skillsCheckbox7">Influencer</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->

                    <!-- Custom Radio -->
                    <div class="form-control mb-2">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" name="skillsCheckboxName" id="skillsCheckbox8">
                        <label class="custom-control-label" for="skillsCheckbox8">Professional sports</label>
                      </div>
                    </div>
                    <!-- End Custom Radio -->
                  </div>
                </div>
              </div>
              <!-- End Form Group -->

              <!-- Form Group -->
              <div class="form-group">
                <label for="addSkillLabel" class="input-label">I have another skill</label>

                <input type="text" class="form-control" name="addSkill" id="addSkillLabel" placeholder="How I could contribute..">
              </div>
              <!-- End Form Group -->

            </div>
            <!-- End Body -->

            <!-- Footer -->
            <div class="card-footer">
              <div class="d-flex align-items-center">
                <button type="button" class="btn btn-ghost-secondary" @click="wizard-=1">
                  <i class="fas fa-angle-left mr-1"></i> Previous step
                </button>

                <div class="ml-auto">
                  <button type="button" class="btn btn-dark" @click="save">
                    Save and continue <i class="fas fa-angle-right ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- End Footer -->
          </div>
          <!-- End Card -->

        </div>
      </div>
    </div>
    
  </main>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Profile from '@/components/settings/Profile'

const store = useStore()
const router = useRouter()
if (store.state.userProfile.registrationCompleted){ router.push('/settings') }

const wizard = ref(1)
const explainWoo = ref(false)
const userProfile = computed(() => store.state.userProfile)

store.dispatch('fetchOrganizations')

const save = () => {
  store.dispatch('updateUserProfile', userProfile.value)
  .then(() => { 
    wizard.value += 1
    if(wizard.value===7) { router.push('/feed') }
  })
  .catch(error => {
    console.log(error)
  })
}

</script>